import axios from 'axios'

export function isE2E() {
  return navigator.userAgent === 'laravel/dusk'
}

export function setupWindowRequestLoadingWatch() {
  // Counter to track the number of active requests
  let activeRequests = 0

  window.loading = false

  // Function to update the data-global-loading attribute
  const updateLoadingState = () => {
    if (activeRequests > 0) {
      window.loading = true
      // document.body.setAttribute('data-global-loading', 'true')
    } else {
      window.loading = false
      // document.body.setAttribute('data-global-loading', 'false')
    }
  }

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Increment the active request count
      activeRequests++
      // Update the loading state
      updateLoadingState()
      return config
    },
    (error) => {
      // Decrement the active request count in case of an error
      activeRequests--
      // Update the loading state
      updateLoadingState()
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Decrement the active request count
      activeRequests--
      // Update the loading state
      updateLoadingState()
      return response
    },
    (error) => {
      // Decrement the active request count in case of an error
      activeRequests--
      // Update the loading state
      updateLoadingState()
      return Promise.reject(error)
    }
  )
}
